/*
Template Name: Jobcy
Author: Themesdesign
Version: 1.0.0
Website: https://themeforest.net/search/themesdesign
Contact: themesdesign.in@gmail.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter:wght@300;400;500;600;700&display=swap");
/********************TOP-BAR********************/
.top-bar {
  background-color: #f8f9fc;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1040;
}

.topbar-social-menu .social-link {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #314047;
  transition: all 0.5s ease;
}
.topbar-social-menu .social-link:hover {
  color: #048565;
}

.language-switch .btn:focus {
  box-shadow: none;
}

/**********************NAVBAR***********************/
.navbar {
  transition: all 0.5s ease;
  padding: 0;
  margin-top: 44px;
  background-color: #fff;
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
}
.navbar .navbar-nav .nav-link {
  color: #314047;
  font-size: 13px;
  background-color: transparent;
  padding: 23px 18px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  position: relative;
  font-weight: 600;
}
.navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link:active, .navbar .navbar-nav .nav-link.active {
  color: #766df4;
}
.navbar .navbar-toggler {
  padding: 0px;
  border: none;
  font-size: 24px;
}
.navbar .navbar-toggler:focus {
  box-shadow: none;
}
.navbar .header-item {
  color: #314047;
  transition: all 0.5s ease;
}

.navbar-brand .logo-dark {
  display: inline-block;
}
.navbar-brand .logo-light {
  display: none;
}

.header-item {
  height: 65px;
  line-height: 65px;
  border: 0;
  border-radius: 0;
  display: inline-block;
}

.notification-wrapper {
  max-height: 250px;
  overflow: hidden scroll;
}
.notification-wrapper .notification-item {
  padding: 15px;
  transition: all 0.5s ease;
}
.notification-wrapper .notification-item:hover {
  background-color: #f8f9fc;
}

.notification-header {
  padding: 1rem;
}

.notification-footer {
  padding: 10px;
}

.noti-icon .count {
  position: absolute;
  top: 15px;
  right: -6px;
  margin-top: -3px;
  margin-right: -5px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  background-color: #da3746;
  border-radius: 40px;
  color: #fff;
  border: 2px solid #fff;
}

.nav-sticky.navbar {
  background: #fff;
  margin-top: 0px;
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
}
.nav-sticky .header-item {
  color: #314047;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 8px 15px;
  }

  .navbar-collapse {
    max-height: 385px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 65px;
    background: #fff;
    padding-bottom: 10px;
    padding-left: 25px;
    box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  }
}
.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border: 0;
  left: auto;
  content: "\f0140";
  font-family: "Material Design Icons";
  top: 35%;
  right: 28px;
  font-size: 14px;
  margin-left: 2px;
  transform: translateY(-50%);
  transition: all 0.3s ease-out;
}
@media (max-width: 991.98px) {
  .arrow-down::after {
    right: 30px;
    position: absolute;
  }
}

@media (max-width: 767.98px) {
  .navbar {
    margin-top: 0px;
  }

  .top-bar {
    display: none;
  }
}
/*****************dark-mode******************/
body[data-layout-mode=dark] .nav-sticky,
body[data-layout-mode=dark] .navbar {
  background-color: #303841;
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.2);
}
body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link:hover, body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link.active,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link:hover,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link.active {
  color: #766df4;
}
body[data-layout-mode=dark] .nav-sticky .header-item,
body[data-layout-mode=dark] .navbar .header-item {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .navbar-toggler {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .notification-wrapper .notification-item:hover {
  background-color: #3d454e;
}
body[data-layout-mode=dark] .noti-icon .count {
  border-color: #303841;
}
body[data-layout-mode=dark] .navbar-brand .logo-dark {
  display: none;
}
body[data-layout-mode=dark] .navbar-brand .logo-light {
  display: inline-block;
}
body[data-layout-mode=dark] .top-bar {
  background-color: #3d454e;
}
body[data-layout-mode=dark] .top-bar .topbar-social-menu .social-link {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .top-bar .topbar-social-menu .social-link:hover {
  color: #048565;
}
@media (max-width: 991.98px) {
  body[data-layout-mode=dark] .navbar-collapse {
    background-color: #303841;
    box-shadow: 0px 3px 10px 0px rgba(118, 123, 129, 0.08);
  }
}

.page-title-box {
  background-image: url("../images/home/page-title.png");
  background-size: cover;
  background-color: #6055f2;
  position: relative;
  padding-top: 225px;
  padding-bottom: 110px;
  background-position: center;
}

.shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
}
.shape > svg {
  width: 100%;
  height: auto;
}

.breadcrumb {
  letter-spacing: 0.5px;
  text-align: center;
}
.breadcrumb .breadcrumb-item {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}
.breadcrumb .breadcrumb-item::before {
  content: "\f0142";
  font-size: 14px;
  color: #fff;
  font-family: "Material Design Icons";
  padding-left: 8px;
}
.breadcrumb .breadcrumb-item:first-child::before {
  content: "";
}
.breadcrumb .breadcrumb-item a {
  color: #fff;
  transition: all 0.5s ease;
}
.breadcrumb .breadcrumb-item a:hover {
  color: #e2e5e8;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .page-title-box {
  background-color: #242C34;
}
body[data-layout-mode=dark] .shape > svg path {
  fill: #283039;
}
body[data-layout-mode=dark] .shape-light > svg path {
  fill: #303841;
}
body[data-layout-mode=dark] .job-pagination .page-item .page-link {
  background-color: #3d454e;
  border-color: #3d454e;
}
body[data-layout-mode=dark] .job-pagination .page-item.active .page-link {
  background-color: #766df4;
  border-color: #766df4;
}

.bg-footer {
  background: #2e3538;
  padding: 60px 0;
  position: relative;
  font-size: 14px;
}

.footer-social-menu li a {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 40px;
  transition: all 0.5s ease;
}
.footer-social-menu li a:hover {
  background-color: #fff;
  color: #766df4;
  border-color: #fff;
}

.footer-list li a {
  display: inline-block;
  padding-bottom: 16px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s ease;
}
.footer-list li a:hover {
  color: #fff;
  letter-spacing: 0.4px;
}

.footer-alt {
  background: #2e3538;
  padding: 20px 0px;
  border-top: 1px solid #343c3f;
}

body[data-layout-mode=dark] .bg-subscribe {
  background-color: #242C34;
}
body[data-layout-mode=dark] .bg-footer, body[data-layout-mode=dark] .footer-alt {
  background: #242C34;
}

.row > * {
  position: relative;
}

/*******color Loop*******/
.bg-soft-primary {
  background-color: rgba(118, 109, 244, 0.15) !important;
  color: #766df4 !important;
}
.bg-soft-primary .border {
  border-color: #766df4 !important;
}

.bg-soft-secondary {
  background-color: rgba(116, 120, 141, 0.15) !important;
  color: #74788d !important;
}
.bg-soft-secondary .border {
  border-color: #74788d !important;
}

.bg-soft-success {
  background-color: rgba(4, 133, 101, 0.15) !important;
  color: #048565 !important;
}
.bg-soft-success .border {
  border-color: #048565 !important;
}

.bg-soft-info {
  background-color: rgba(95, 208, 243, 0.15) !important;
  color: #5fd0f3 !important;
}
.bg-soft-info .border {
  border-color: #5fd0f3 !important;
}

.bg-soft-warning {
  background-color: rgba(247, 204, 83, 0.15) !important;
  color: #f7cc53 !important;
}
.bg-soft-warning .border {
  border-color: #f7cc53 !important;
}

.bg-soft-danger {
  background-color: rgba(218, 55, 70, 0.15) !important;
  color: #da3746 !important;
}
.bg-soft-danger .border {
  border-color: #da3746 !important;
}

.bg-soft-pink {
  background-color: rgba(232, 62, 140, 0.15) !important;
  color: #e83e8c !important;
}
.bg-soft-pink .border {
  border-color: #e83e8c !important;
}

.bg-soft-light {
  background-color: rgba(248, 249, 252, 0.15) !important;
  color: #f8f9fc !important;
}
.bg-soft-light .border {
  border-color: #f8f9fc !important;
}

.bg-soft-dark {
  background-color: rgba(49, 64, 71, 0.15) !important;
  color: #314047 !important;
}
.bg-soft-dark .border {
  border-color: #314047 !important;
}

.bg-soft-purple {
  background-color: rgba(118, 109, 244, 0.15) !important;
  color: #766df4 !important;
}
.bg-soft-purple .border {
  border-color: #766df4 !important;
}

.bg-soft-blue {
  background-color: rgba(31, 134, 239, 0.15) !important;
  color: #1f86ef !important;
}
.bg-soft-blue .border {
  border-color: #1f86ef !important;
}

.bg-soft-green-dark {
  background-color: rgba(4, 133, 101, 0.15) !important;
  color: #048565 !important;
}
.bg-soft-green-dark .border {
  border-color: #048565 !important;
}

.bg-soft-white {
  background-color: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}
.bg-soft-white .border {
  border-color: #fff !important;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.section-title .title {
  font-size: 30px;
  line-height: 1.375;
}

.para-desc {
  max-width: 600px;
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

/*******font-size*******/
.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

/*******font-weight*******/
.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.bg-overlay {
  background-color: rgba(49, 64, 71, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

::selection {
  color: #fff;
  background: #766df4;
}

/*************Avtar size*************/
.avatar-xs {
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
}

.avatar-md {
  height: 4rem;
  width: 4rem;
}

.avatar-lg {
  height: 5rem;
  width: 5rem;
}

.uim-svg {
  fill: #766df4 !important;
}
.uim-svg svg {
  width: 24px;
  height: 24px;
  vertical-align: inherit;
}

.icons-sm svg {
  height: 18px;
  width: 18px;
}

.icons-md svg {
  height: 32px;
  width: 32px;
}

.icons-lg svg {
  height: 40px;
  width: 40px;
}

.icons-xl svg {
  height: 48px;
  width: 48px;
}

/*******link color*******/
.primary-link {
  color: #314047;
  transition: all 0.5s ease;
}
.primary-link:hover {
  color: #766df4 !important;
}

.map {
  line-height: 0;
  margin-bottom: -1px;
}

.table th {
  font-weight: 500;
}

.alert-dismissible .btn-close:focus {
  box-shadow: none;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: #74788d;
}

#back-to-top {
  width: 35px;
  height: 35px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  background: #8c85f6;
  border-color: transparent;
  border-radius: 5px;
  color: #fff;
  transition: all 0.5s ease;
  display: none;
  z-index: 99;
}
#back-to-top:hover {
  border-radius: 5px;
  animation: fade-up 1.5s infinite linear;
  background-color: #766df4;
}

@keyframes fade-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }

  .custom-container {
    max-width: 90%;
  }
}
/*****************dark-mode******************/
body[data-layout-mode=dark] .primary-link {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .alert-dismissible .btn-close {
  filter: invert(1);
}
body[data-layout-mode=dark] .border-bottom,
body[data-layout-mode=dark] .border-top,
body[data-layout-mode=dark] .border,
body[data-layout-mode=dark] .border-end {
  border-color: #3d454e !important;
}

.ui-elements-bar {
  padding: 24px;
  background-color: #fff;
  position: sticky;
  overflow-y: auto;
  top: 90px;
  height: calc(100vh - 165px);
  margin-right: 20px;
}
.ui-elements-bar .nav .nav-item .nav-link {
  color: #74788d;
  font-weight: 500;
}
.ui-elements-bar .nav .nav-item .nav-link:hover {
  color: #314047;
}
.ui-elements-bar .nav .nav-item.active .nav-link {
  color: #314047;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .ui-elements-bar {
  background-color: #303841;
}
body[data-layout-mode=dark] .ui-elements-bar .nav .nav-item .nav-link {
  color: #CED1D2;
}
body[data-layout-mode=dark] .ui-elements-bar .nav .nav-item .nav-link:hover {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .offcanvas {
  background-color: #303841;
  border-color: #303841;
}
body[data-layout-mode=dark] .progress {
  background-color: #303841;
}
body[data-layout-mode=dark] .modal .modal-content {
  background-color: #303841;
  border-color: #303841;
}
body[data-layout-mode=dark] .modal .modal-content .modal-header,
body[data-layout-mode=dark] .modal .modal-content .modal-footer {
  border-color: #3d454e;
}
body[data-layout-mode=dark] .btn-close {
  filter: invert(1);
}
body[data-layout-mode=dark] .table {
  color: #f8f9fa;
  border-color: #3d454e;
}
body[data-layout-mode=dark] .table-striped > tbody > tr:nth-of-type(odd) {
  color: #f8f9fa;
  --bs-table-accent-bg: #283039;
}

.form-control,
.form-select {
  padding: 10px;
  font-size: 14px;
  border-color: #dbdfe2;
  font-weight: 500;
}
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder {
  color: #adb5bd;
  font-size: 13px;
}
.form-control::-moz-placeholder,
.form-select::-moz-placeholder {
  /* Firefox 19+ */
  color: #adb5bd;
  font-size: 13px;
}
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder {
  /* IE 10+ */
  color: #adb5bd;
  font-size: 13px;
}
.form-control:-moz-placeholder,
.form-select:-moz-placeholder {
  /* Firefox 18- */
  color: #adb5bd;
  font-size: 13px;
}

[type=search]::-webkit-search-cancel-button,
.form-check-label, .form-check-input {
  cursor: pointer;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .form-control, body[data-layout-mode=dark] .form-check-input {
  color: #f8f9fa;
  border-color: #3d454e;
  background-color: #303841;
}
body[data-layout-mode=dark] .form-control:checked, body[data-layout-mode=dark] .form-check-input:checked {
  background-color: #766df4;
  border-color: #766df4;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 99999;
  transition: all 0.5s ease;
  visibility: visible;
  opacity: 1;
}
#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#status {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(118, 109, 244, 0.15);
}
#status ul {
  display: flex;
}
#status ul li {
  list-style: none;
  height: 20px;
  width: 20px;
  background-color: #766df4;
  margin: 0 4px;
  border-radius: 50%;
  animation: bounce 1s linear infinite;
}
#status ul li:nth-child(1) {
  animation-delay: 0.2s;
  background-color: #1d10d2;
}
#status ul li:nth-child(2) {
  animation-delay: 0.6s;
  background-color: #2012e9;
}
#status ul li:nth-child(3) {
  animation-delay: 0.1s;
  background-color: #3326ef;
}
#status ul li:nth-child(4) {
  animation-delay: 0.8s;
  background-color: #493ef0;
}
#status ul li:nth-child(5) {
  animation-delay: 0.5s;
  background-color: #6055f2;
}
#status ul li:nth-child(6) {
  animation-delay: 0.1s;
  background-color: #766df4;
}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

.choices__list--multiple .choices__item {
  background-color: #766df4;
  border-color: #766df4;
  margin-bottom: 0;
}

.choices__list--dropdown {
  z-index: 2;
  border-color: #dbdfe2;
  font-weight: 500;
  max-height: 280px;
}
.choices__list--dropdown .choices__item--selectable {
  padding: 0;
  line-height: 43px;
  min-height: 44px;
  padding-left: 15px;
  padding-right: 29px;
  text-align: left;
  font-size: 15px;
}
.choices__list--dropdown .choices__item--selectable::after {
  display: none;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  color: #766df4;
  background-color: #f8f9fc;
}

.choices__input {
  background-color: transparent;
  margin-bottom: 0;
}

.choices__inner {
  color: #314047;
  background-color: #fff;
  padding: 6px 10px;
  min-height: 39px;
  border: 1px solid;
  border-color: #dbdfe2;
  font-weight: 500;
}
.choices__inner:focus {
  border: 1px solid;
  border-color: #dbdfe2;
}

.choices[data-type*=select-one] .choices__input {
  background-color: transparent;
  border: 1px solid #766df4;
  margin: 10px;
  border-radius: 5px;
  width: 90%;
}
.choices[data-type*=select-one]::after {
  border: 0;
  right: 11.5px;
  left: auto;
  content: "\f0140";
  font-family: "Material Design Icons";
  top: 35%;
  right: 28px;
}
.choices[data-type*=select-one].is-open .choices__list--dropdown,
.choices[data-type*=select-one].is-open .choices__inner {
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  border-color: #dbdfe2;
}
.choices[data-type*=select-one].is-open::after {
  margin-top: -2.5px;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border: 1px solid;
  border-color: #dbdfe2;
}

.selection-widget .form-select {
  padding: 8px 20px;
  color: #495057;
  border-color: #dbdfe2;
}
.selection-widget .form-select:focus {
  box-shadow: none;
}
.selection-widget .form-select option {
  padding: 20px;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .choices__inner,
body[data-layout-mode=dark] .choices__list--dropdown,
body[data-layout-mode=dark] .choices[data-type*=select-one] .choices__input,
body[data-layout-mode=dark] .choices__input {
  background-color: #303841;
  border-color: #3d454e;
  color: #f8f9fa;
}
body[data-layout-mode=dark] .choices__inner:focus,
body[data-layout-mode=dark] .choices__list--dropdown:focus,
body[data-layout-mode=dark] .choices[data-type*=select-one] .choices__input:focus,
body[data-layout-mode=dark] .choices__input:focus {
  border-color: #3d454e;
}
body[data-layout-mode=dark] .choices[data-type*=select-one].is-open .choices__list--dropdown,
body[data-layout-mode=dark] .choices[data-type*=select-one].is-open .choices__inner {
  border-color: #3d454e;
}
body[data-layout-mode=dark] .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #3d454e;
  color: #766df4;
}

.gslide-description .gdesc-inner .gslide-desc {
  font-family: "Inter", sans-serif;
  font-size: 15px;
}
.gslide-description .gdesc-inner .gslide-title {
  font-family: "Inter", sans-serif;
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: 500;
}

#home {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-home {
  padding: 300px 0px 240px 0px;
  background-color: #6055f2;
}
@media (max-width: 767.98px) {
  .bg-home {
    padding: 155px 0px 110px 0px;
    background-color: #766df4;
  }
}
.bg-home .bg-overlay {
  background: url("../images/home/img-01.png");
  background-size: cover;
  background-position: center;
}

.filter-search-form {
  position: relative;
}
.filter-search-form i {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 1;
  color: #766df4;
  font-size: 20px;
}

.registration-form {
  position: relative;
}
.registration-form .submit-btn {
  border-radius: 8px;
}
.registration-form .form-control,
.registration-form .choices[data-type*=select-one] .choices__inner {
  border-radius: 8px;
}

@media (min-width: 992px) {
  .filter-border::before {
    content: "";
    border-right: 1px solid #e2e5e8;
    position: absolute;
    right: 4px;
    height: 38px;
    top: 10px;
    z-index: 1;
  }

  .registration-form {
    position: relative;
  }
  .registration-form .submit-btn {
    border-radius: 0px 8px 8px 0px;
  }
  .registration-form .form-control {
    border-radius: 8px 0px 0px 8px;
  }
  .registration-form .choices[data-type*=select-one] .choices__inner {
    border-radius: 0;
  }
}
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  box-shadow: none !important;
  border: 0;
  font-size: 15px;
  height: 60px;
  padding: 16px 6px 15px 45px;
  border-radius: 0;
}

.treding-keywords li a {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.5s ease;
}
.treding-keywords li a:hover {
  color: #fff;
}

.bg-home2 {
  padding: 198px 0 140px 0;
  background-color: rgba(118, 109, 244, 0.1);
  overflow-x: clip;
}
@media (max-width: 767.98px) {
  .bg-home2 {
    padding: 160px 0 125px 0;
  }
}

@media (max-width: 991.98px) {
  .home-img {
    height: auto;
    width: 100%;
  }
}
.bg-home3 {
  padding: 210px 0 95px 0;
  background-color: rgba(118, 109, 244, 0.1);
  overflow-x: clip;
}
@media (max-width: 767.98px) {
  .bg-home3 {
    padding: 160px 0 90px 0;
  }
}

.homeslider .home-slide-box {
  position: relative;
}
.homeslider .home-slide-box .bg-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
}
.homeslider .home-slide-box .home-slide-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.homeslider .home-slide-box .home-slide-content .slide-para {
  font-family: "Dancing Script", cursive;
}
@media (min-width: 992px) {
  .homeslider {
    width: 200%;
  }
  .homeslider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .homeslider .swiper-slide {
    width: 60%;
    height: 100%;
  }
  .homeslider .swiper-slide:nth-child(2n) {
    width: 60%;
    height: 100%;
  }
  .homeslider .swiper-slide:nth-child(3n) {
    width: 60%;
    height: 100%;
  }
}

.quote-icon .icon {
  position: absolute;
  top: -32px;
  left: 7px;
  z-index: 9;
  font-size: 102px;
  color: #fff;
}
.quote-icon .icon-2 {
  font-size: 100px;
  position: absolute;
  top: -31px;
  left: 6px;
  z-index: 9;
}

@media (min-width: 768px) {
  .bg-home2 .filter-border::before,
.bg-home3 .filter-border::before {
    content: "";
    border-right: 1px solid #e2e5e8;
    position: absolute;
    right: 4px;
    height: 38px;
    top: 10px;
    z-index: 1;
  }
  .bg-home2 .registration-form .submit-btn,
.bg-home3 .registration-form .submit-btn {
    border-radius: 0px 8px 8px 0px;
  }
  .bg-home2 .registration-form .form-control,
.bg-home3 .registration-form .form-control {
    border-radius: 8px 0px 0px 8px;
  }
  .bg-home2 .registration-form .choices[data-type*=select-one] .choices__inner,
.bg-home3 .registration-form .choices[data-type*=select-one] .choices__inner {
    border-radius: 0PX;
  }
}
/*****************dark-mode******************/
body[data-layout-mode=dark] .bg-home {
  background-color: #373f4a;
}
body[data-layout-mode=dark] .filter-border::before {
  border-color: #3d454e;
}
body[data-layout-mode=dark] .registration-form .form-control {
  background-color: #303841;
}

.popu-category-box {
  padding: 2.5rem 1.5rem;
  transition: all 0.5s ease;
}
.popu-category-box .popu-category-icon {
  width: 64px;
  height: 64px;
  line-height: 64px;
  border-radius: 8px;
  text-align: center;
  font-size: 28px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(118, 109, 244, 0.1);
}
.popu-category-box:hover {
  transform: translateY(-8px);
}

.job-Categories-box .job-Categories-list li {
  background-color: #fff;
  padding: 8px 14px;
  border-radius: 4px;
  margin-bottom: 14px;
  box-shadow: 0 0.75rem 1.5rem rgba(49, 64, 71, 0.03);
}
.job-Categories-box .job-Categories-list li:last-child {
  margin-bottom: 0px;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .job-Categories-box .job-Categories-list li {
  background-color: #3d454e;
}

.job-list-menu {
  background-color: #fff;
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  border-radius: 10px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.job-list-menu .nav-item .nav-link {
  padding: 10px 18px;
  color: #314047;
  font-weight: 500;
  transition: all 0.5s ease;
  border-radius: 10px;
}
.job-list-menu .nav-item .nav-link.active {
  color: #fff;
  background-color: #766df4;
}

.job-box {
  overflow: hidden;
  transition: all 0.5s ease;
}
.job-box .bookmark-label {
  width: 200px;
  position: absolute;
  top: -5px;
  left: -84px;
  transform: rotate(-45deg);
  z-index: 1;
  color: #fff;
  background: rgba(118, 109, 244, 0.15);
  transition: all 0.5s ease;
  padding: 6px;
  font-size: 20px;
  padding-top: 8px;
}
.job-box:hover {
  transform: translateY(-8px);
  border-color: #766df4;
}
.job-box:hover .bookmark-label {
  background-color: #766df4;
}

.filler-job-form i {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 1;
  color: #766df4;
  font-size: 20px;
}

.filter-job-input-box,
.filter-job-input-box.form-select,
.filler-job-form .choices__inner {
  box-shadow: none !important;
  font-size: 14px;
  height: 44px;
  padding: 6px 6px 6px 45px;
  border-radius: 6px;
}
.filter-job-input-box:focus,
.filter-job-input-box.form-select:focus,
.filler-job-form .choices__inner:focus {
  border-color: #eff0f2;
}

.side-bar .custom-control {
  margin: 12px 22px;
}
.side-bar .form-check-input {
  width: 16px;
  height: 16px;
}
.side-bar .accordion .accordion-item {
  border: none;
}
.side-bar .accordion .accordion-item .accordion-button {
  background-color: rgba(118, 109, 244, 0.15);
  color: #314047;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
}
.side-bar .accordion .accordion-item .accordion-button::after {
  content: "\f0140";
  font-family: "Material Design Icons";
  background: none;
}

.tag-cloud {
  color: #74788d;
  background-color: #f8f9fc;
  transition: all 0.5s ease;
}
.tag-cloud:hover {
  color: #fff;
  background-color: #766df4;
}

.noUi-horizontal {
  height: 10px;
  background: #fff;
  border-color: #e2e5e8;
}
.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border-color: #766df4;
  background: #766df4;
  box-shadow: none;
}
.noUi-horizontal .noUi-handle::after, .noUi-horizontal .noUi-handle::before {
  content: "";
  opacity: 0;
}

.job-list-header .registration-form-box .form-control,
.job-list-header .registration-form-box .form-select {
  padding: 6px 8px 6px 45px;
}

.wedget-popular-title .popular-box {
  position: relative;
  padding: 6px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid rgba(239, 240, 242, 0.65);
}
.wedget-popular-title .popular-box .number {
  text-align: center;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 5px;
  font-size: 14px;
  color: #766df4;
  background-color: rgba(118, 109, 244, 0.15);
}

.favorite-icon a {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #eff0f2;
  border-radius: 6px;
  color: rgba(173, 181, 189, 0.55);
  transition: all 0.5s ease;
}
.favorite-icon a:hover {
  color: #fff;
  background-color: #da3746;
  border-color: #da3746;
}

.bookmark-post .bookmark-label, .job-box.bookmark-post .bookmark-label {
  background-color: #766df4;
}
.bookmark-post .favorite-icon a, .job-box.bookmark-post .favorite-icon a {
  background-color: #da3746;
  color: #fff;
  border-color: danger;
}

.job-box .favorite-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .job-list-menu {
  background-color: #283039;
}
body[data-layout-mode=dark] .job-list-menu .nav-item .nav-link {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .side-bar .accordion .accordion-button {
  color: #f8f9fc;
}
body[data-layout-mode=dark] .wedget-popular-title .popular-box {
  border-color: #3d454e;
}
body[data-layout-mode=dark] .job-box:hover {
  border-color: #766df4;
}
body[data-layout-mode=dark] .favorite-icon a {
  border-color: #3d454e;
}
body[data-layout-mode=dark] .noUi-horizontal {
  background-color: #303841;
  border-color: #303841;
  box-shadow: none;
}
body[data-layout-mode=dark] .noUi-horizontal .noUi-handle {
  border-color: #63686f;
  background-color: #63686f;
  box-shadow: none;
}

.bg-subscribe {
  position: relative;
  padding: 80px 0px;
  overflow: hidden;
  background-color: #343c3f;
  bottom: -1px;
}
.bg-subscribe .email-img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0.35;
}

.subscribe-form .form-control {
  max-width: 350px;
  padding: 9px 20px;
  border-color: rgba(226, 229, 232, 0.35);
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
}

.process-box {
  transition: all 0.5s ease;
  padding: 1.5rem;
}
.process-box .process-img {
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 50%;
  background-color: rgba(118, 109, 244, 0.05);
}
.process-box:hover {
  transform: translateY(-8px);
}

.process-menu.nav-pills .nav-link {
  color: #314047;
  margin-bottom: 22px;
  position: relative;
  padding: 10px 0;
}
.process-menu.nav-pills .nav-link .number {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(116, 120, 141, 0.15);
  color: #74788d;
  border-radius: 40px;
}
.process-menu.nav-pills .nav-link.active {
  background-color: transparent !important;
  color: #766df4;
}
.process-menu.nav-pills .nav-link.active .number {
  color: #fff;
  background-color: #766df4;
}
.process-menu.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  border: 1px dashed rgba(118, 109, 244, 0.4);
  height: 65px;
  left: 17px;
  top: 54px;
}
.process-menu.nav-pills .nav-link:last-child::before {
  border: none;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .process-menu .nav-link {
  color: #fff;
}
body[data-layout-mode=dark] .process-menu .nav-link.active {
  color: #766df4;
}

.testi-box {
  position: relative;
  max-width: 630px;
  border: none;
  margin: 0 auto;
  text-align: center;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #766df4;
  opacity: 0.5;
  border-radius: 8px;
  transition: all 0.5s ease;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  width: 26px;
}

.blog-box {
  background-color: transparent;
  border: none;
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  border-radius: 6px;
}
.blog-box .blog-img {
  border-radius: 6px 6px 0px 0px;
}
.blog-box .blog-img .bg-overlay {
  opacity: 0;
  transition: all 0.5s ease;
}
.blog-box .blog-img .author {
  transition: all 0.5s ease;
  position: absolute;
  top: 15px;
  left: 15px;
  opacity: 0;
}
.blog-box .blog-img .likes {
  transition: all 0.5s ease;
  position: absolute;
  bottom: 15px;
  right: 15px;
  opacity: 0;
}
.blog-box:hover .blog-img .bg-overlay {
  opacity: 0.6;
}
.blog-box:hover .blog-img .author {
  opacity: 1;
}
.blog-box:hover .blog-img .likes {
  opacity: 1;
}

.post-preview .blog-img, .blog-masonry-box .blog-img {
  transition: all 0.5s ease;
  transform: scale(1.3);
}
.post-preview:hover .blog-img, .blog-masonry-box:hover .blog-img {
  transform: scale(1.3) translateX(-10px);
}

.widget-popular-post .widget-popular-post-img {
  width: 85px;
  height: 60px;
  object-fit: cover;
}
.widget-popular-post a {
  color: #314047;
}

.sd-title {
  position: relative;
  border-bottom: 2px solid #e2e5e8;
}
.sd-title::before {
  content: "";
  background-color: #766df4;
  width: 32%;
  height: 2px;
  position: absolute;
  bottom: -2px;
}

.widget-social-menu li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  color: #314047;
  background-color: #f8f9fc;
  border-radius: 40px;
  transition: all 0.5s ease;
}
.widget-social-menu li a:hover {
  color: #fff;
  background-color: #766df4;
}

.blog-grid-box {
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  border: 0;
  transition: all 0.5s ease;
}
.blog-grid-box:hover {
  transform: translateY(-8px);
}

.blog-modern-box .bg-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 10%, transparent);
}
.blog-modern-box img {
  height: 400px;
  object-fit: cover;
  transition: all 0.5s ease;
  transform: scale(1.3);
}
.blog-modern-box .card-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.blog-modern-box:hover img {
  transform: scale(1.3) translateX(-20px);
}

.blog-blockquote .blockquote p {
  max-width: 545px;
  margin: 0 auto;
}

.blog-social-menu li .social-link {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.blog-author-sidebar .blog-title {
  font-size: 16px;
  border: 1px solid;
  text-align: center;
  padding: 12px 0;
  border-color: #e2e5e8 transparent #e2e5e8 transparent;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .widget-social-menu li a, body[data-layout-mode=dark] .candidate-detail-social-menu li a {
  color: #74788d;
  background-color: #3d454e;
}
body[data-layout-mode=dark] .widget-social-menu li a:hover, body[data-layout-mode=dark] .candidate-detail-social-menu li a:hover {
  color: #fff;
  background-color: #766df4;
}
body[data-layout-mode=dark] .blog-grid-box {
  box-shadow: 0 0 3px rgba(248, 249, 250, 0.1);
}

.about-feature {
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  border: 15px;
  transition: all 0.5s ease;
}
.about-feature .featrue-icon {
  height: 45px;
  width: 45px;
  line-height: 40px;
  border-radius: 8px;
  font-size: 24px;
  text-align: center;
  color: #766df4;
  background: linear-gradient(-45deg, transparent, rgba(118, 109, 244, 0.15));
}
.about-feature:hover {
  transform: scale(1.02);
  box-shadow: 0 5px 6px rgba(49, 64, 71, 0.1);
}

.about-social-menu li a {
  font-size: 17px;
  color: #74788d;
  transition: all 0.5s ease;
}
.about-social-menu li a:hover {
  color: #766df4;
}

.about-testimonial-menu.nav-pills .nav-link img {
  max-width: 100%;
}
.about-testimonial-menu.nav-pills .nav-link .about-testi-bg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(49, 64, 71, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  opacity: 0;
}
.about-testimonial-menu.nav-pills .nav-link.active, .about-testimonial-menu.nav-pills .nav-link:hover {
  background-color: transparent !important;
}
.about-testimonial-menu.nav-pills .nav-link.active .about-testi-bg-overlay, .about-testimonial-menu.nav-pills .nav-link:hover .about-testi-bg-overlay {
  opacity: 1;
}

.about-tab-content .tab-pane {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.about-cta {
  padding: 50px 40px;
  border: 1px solid #eff0f2;
  border-radius: 20px;
}

.counter {
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  height: 2.4rem;
  font-size: 2.4rem;
  font-weight: 600;
}
.counter > span {
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 2s ease;
  transform: translateY(0);
  line-height: 1;
}
.counter > span span {
  flex: 0 0 100%;
  height: 100%;
}

.about-list li {
  margin-bottom: 14px;
  padding-left: 25px;
}
.about-list li::before {
  font-family: Material Design Icons;
  content: "\f0e1e";
  position: absolute;
  left: 12px;
  color: #048565;
}

@media (min-width: 1200px) {
  .about-img {
    width: 180%;
  }
}

.service-box {
  border: none;
  transition: all 0.5s ease;
}
.service-box:hover {
  transform: translateY(-8px);
}

.team-box {
  overflow: hidden;
}
.team-box .team-img img {
  position: relative;
  width: 215px;
  height: 215px;
  border-radius: 50%;
}
.team-box .team-img .team-social {
  position: absolute;
  top: 58px;
  left: 25px;
  text-align: center;
  opacity: 0;
  transform: translate(-20px, -20px);
  transition: all 0.3s ease-out;
}
.team-box .team-img .team-social a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #766df4;
  color: #fff;
}
.team-box:hover .team-social {
  opacity: 1;
  transform: translate(0, 0);
  transition-delay: 0.15s;
}

.pricing-details .pricing-item {
  padding: 9px 0;
}
.pricing-details .pricing-item i {
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  text-align: center;
  border-radius: 40px;
}

.pricing-box {
  border: none;
  transition: all 0.5s ease;
}
.pricing-box .pricing-icon {
  position: absolute;
  top: -56px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  line-height: 5rem;
  border: 6px solid #fff;
  height: 90px;
  width: 90px;
  line-height: 96px;
}
.pricing-box:hover {
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  transform: translateY(-8px);
}

.pricing-counter {
  background-image: url("../images/bg-counter.svg");
  background-size: cover;
  background-position: center;
  padding: 35px;
  border-radius: 20px;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .pricing-box .pricing-icon {
  border-color: #283039;
}

.faq-menu.nav-pills .nav-item .nav-link {
  color: #314047;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  font-size: 17px;
}
.faq-menu.nav-pills .nav-item .nav-link.active {
  color: #766df4;
  background-color: transparent !important;
  border-bottom-color: #766df4;
}

.faq-box .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  color: #766df4;
  box-shadow: none;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .faq-menu .nav-item .nav-link {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .faq-menu .nav-item .nav-link.active {
  color: #766df4;
}
body[data-layout-mode=dark] .accordion-button {
  color: #f8f9fc;
}

.job-grid-box {
  border-radius: 14px;
  transition: all 0.5s ease;
}
.job-grid-box .favorite-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}
.job-grid-box:hover {
  transform: translateY(-10px);
}

.job-overview .icon, .candidate-contact-details .icon {
  height: 46px;
  line-height: 46px;
  width: 46px;
  border-radius: 46px;
  text-align: center;
  font-size: 20px;
}

.job-details-compnay-profile {
  margin-top: -34px;
  position: relative;
  margin-left: 24px;
}

.job-details-menu {
  position: sticky;
  top: 120px;
}
.job-details-menu .nav-link {
  color: #314047;
  border: 1px solid #f8f9fc;
  margin-top: 15px;
  padding: 22px;
  cursor: pointer;
}
.job-details-menu .nav-link.active {
  color: #314047;
  background-color: rgba(118, 109, 244, 0.07);
}

.job-detail-list li {
  padding-left: 25px;
  position: relative;
  margin-top: 12px;
}
.job-detail-list li i {
  color: #048565;
  font-size: 12px;
  line-height: 23px;
  left: 0;
  position: absolute;
  top: 0;
}

.candidate-list-box {
  transition: all 0.5s ease;
}
.candidate-list-box .favorite-icon {
  position: absolute;
  right: 22px;
  top: 22px;
}
.candidate-list-box:hover {
  transform: translateY(-8px);
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  border-color: #766df4;
}

.profile-active {
  padding: 5px;
  top: -4px;
  right: -5px;
  border: 2px solid #fff;
}

.featured-label {
  top: 10px;
  position: absolute;
  right: 0px;
}
.featured-label .featured {
  margin-bottom: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  background: #766df4;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  padding: 6px;
}
.featured-label .featured::before {
  content: "";
  position: absolute;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #766df4;
  left: -11px;
  top: 0;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .profile-active {
  border-color: #303841;
}

.page-sub-title {
  font-weight: 17px;
  font-weight: 500px;
  letter-spacing: 0.5px;
}

.candidate-detail-social-menu li .social-link {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  border-radius: 40px;
  color: #74788d;
  background-color: #f8f9fc;
  transition: all 0.5s ease;
}
.candidate-detail-social-menu li .social-link:hover {
  color: #fff;
  background-color: #766df4;
}

.candidate-profile-overview li, .profile-contact li {
  padding: 10px 0;
}
.candidate-profile-overview li label, .profile-contact li label {
  min-width: 118px;
  font-weight: 500;
}

.candidate-education-content {
  position: relative;
}
.candidate-education-content .circle {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 40px;
}
.candidate-education-content::before {
  content: "";
  position: absolute;
  border: 1px dashed rgba(118, 109, 244, 0.4);
  height: 98px;
  left: 16px;
  top: 44px;
}
.candidate-education-content:last-child::before {
  opacity: 0;
}

.review-rating i {
  font-size: 16px;
  letter-spacing: -0.016rem;
}

.candidate-portfolio-box {
  overflow: hidden;
}
.candidate-portfolio-box .bg-overlay {
  background-color: rgba(49, 64, 71, 0);
}
.candidate-portfolio-box img,
.candidate-portfolio-box .bg-overlay,
.candidate-portfolio-box .zoom-icon {
  transition: all 0.5s ease;
}
.candidate-portfolio-box .zoom-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  color: #fff;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.candidate-portfolio-box:hover img {
  transform: scale(1.2);
}
.candidate-portfolio-box:hover .bg-overlay {
  background-color: rgba(49, 64, 71, 0.3);
}
.candidate-portfolio-box:hover .zoom-icon {
  opacity: 1;
}

.bg-auth {
  padding: 40px 0;
  background-color: rgba(118, 109, 244, 0.1);
  align-items: center;
  display: flex;
  min-height: 100vh;
}
@media (max-width: 991.98px) {
  .bg-auth {
    padding: 60px 0;
  }
}

.auth-box {
  border: none;
  box-shadow: 0 5px 6px rgba(49, 64, 71, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
.auth-box .auth-content {
  background-color: #493ef0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.auth-box .logo-dark {
  display: inline-block;
}
.auth-box .logo-light {
  display: none;
}
.auth-box .auth-form .form-control {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}
.auth-box .auth-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.45);
}
.auth-box .auth-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.45);
}
.auth-box .auth-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.45);
}
.auth-box .auth-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.45);
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .bg-auth {
  background-color: #303841;
}
body[data-layout-mode=dark] .auth-box {
  background-color: #303841;
  box-shadow: 0 5px 13px rgba(49, 64, 71, 0.1);
}
body[data-layout-mode=dark] .auth-box .logo-light {
  display: inline-block;
}
body[data-layout-mode=dark] .auth-box .logo-dark {
  display: none;
}

.bg-coming-soon {
  position: relative;
}
@media (max-width: 991.98px) {
  .bg-coming-soon {
    padding: 150px 0;
    height: auto;
  }
}

.coming-soon-subacribe .form-control {
  border-color: rgba(173, 181, 189, 0.5);
  background-color: transparent;
}

.countdownlist-item {
  width: 25%;
  text-align: center;
}
.countdownlist-item:last-of-type .count-num::after {
  display: none;
}

.count-title {
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
  color: rgba(49, 64, 71, 0.5);
  text-transform: uppercase;
}

.count-num {
  padding: 16px 8px;
  position: relative;
  font-weight: 600;
  font-size: 32px;
}
@media (max-width: 575.98px) {
  .count-num {
    font-size: 18px;
  }
}
.count-num::after {
  content: ":";
  font-size: 20px;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  color: #314047;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] .bg-coming-soon,
body[data-layout-mode=dark] .bg-error {
  background-color: #283039;
}
body[data-layout-mode=dark] .count-title {
  color: #f8f9fa;
}
body[data-layout-mode=dark] .count-num::after {
  color: #f8f9fa;
}

.profile-page {
  padding: 180px 0 80px 0;
  position: relative;
}
@media (max-width: 767.98px) {
  .profile-page {
    padding: 95px 0 70px 0;
  }
}

.profile-document .profile-document-list .icon {
  width: 40px;
  height: 40px;
  line-height: 38px;
  border: 1px solid rgba(116, 120, 141, 0.2);
  border-radius: 6px;
  color: #74788d;
  text-align: center;
  font-size: 20px;
}

.profile-content-nav.nav-pills .nav-item .nav-link {
  color: #314047;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  font-size: 16px;
  padding: 12px 26px;
}
.profile-content-nav.nav-pills .nav-item .nav-link.active {
  color: #766df4;
  background-color: transparent !important;
  border-bottom-color: #766df4;
}

.profile-user {
  position: relative;
  display: inline-block;
}
.profile-user .profile-photo-edit {
  position: absolute;
  bottom: 5px;
  right: 0px;
}
.profile-user .profile-photo-edit i {
  color: #314047;
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 0;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(49, 64, 71, 0.08);
  cursor: pointer;
}
.profile-user .profile-img-file-input {
  display: none;
}
.profile-user .profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

ul.working-days {
  padding: 0;
  margin: 0;
}
ul.working-days li {
  list-style: none;
  padding: 8px 0;
}
ul.working-days li span {
  float: right;
  color: #82858E;
  font-size: 13px;
}

#style-switcher {
  transition: all 0.4s;
  background: none repeat scroll 0 0 #f8f9fc;
  box-shadow: 0px 4px 12px rgba(49, 64, 71, 0.07);
  left: -165px;
  position: fixed;
  top: 35.5%;
  width: 165px;
  z-index: 999;
  padding: 10px 5px;
}
#style-switcher .settings {
  background: #212529;
  color: #fff;
  font-size: 20px;
  position: absolute;
  right: -45px;
  top: 0;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
}

ul.pattern {
  overflow: hidden;
  border-radius: 0px;
}
ul.pattern li {
  float: left;
  margin: 2px;
}
ul.pattern li .color-list {
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 30px;
  margin: 5px;
}
ul.pattern .color1 {
  background-color: #766df4;
}
ul.pattern .color2 {
  background-color: #1f86ef;
}
ul.pattern .color3 {
  background-color: #02af74;
}

.mode-btn {
  height: 40px;
  width: 80px;
  display: inline-block;
  line-height: 40px;
  background-color: #766df4;
  text-align: center;
}

.mode-dark {
  display: none;
  transition: all 0.5s ease;
}

.mode-light {
  display: blog;
  transition: all 0.5s ease;
}

/*****************dark-mode******************/
body[data-layout-mode=dark] #style-switcher {
  background-color: #242C34;
}
body[data-layout-mode=dark] .mode-dark {
  display: inline-block;
}
body[data-layout-mode=dark] .mode-light {
  display: none;
}
body[data-layout-mode=dark] .card {
  background-color: #283039;
  border-color: #3d454e;
}